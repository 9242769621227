import React from "react";

function EyeIconOutlined() {
  return (
    <svg width="84" height="84" fill="none">
      <circle cx="42" cy="42" r="40" stroke="currentColor" strokeWidth="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.512 46.38a4.78 4.78 0 0 0 3.893-7.556 2.053 2.053 0 1 1-.741-.82 4.78 4.78 0 1 0-3.152 8.375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.405 38.824a2.053 2.053 0 1 1-.741-.82 4.762 4.762 0 0 0-3.902-1.127 4.782 4.782 0 1 0 5.373 5.943 4.789 4.789 0 0 0-.73-3.996Zm-2.055 4.92a2.825 2.825 0 0 1-3.788-4.19 4.01 4.01 0 0 0 3.788 4.19Z"
        fill="currentColor"
      />
      <path
        d="M52.243 41.6c0 5.928-4.806 10.734-10.734 10.734S30.775 47.528 30.775 41.6s4.806-10.734 10.734-10.734S52.243 35.672 52.243 41.6Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M22.418 42.727a37.06 37.06 0 0 1-1.081-1.127c.27-.295.634-.679 1.081-1.126a42.932 42.932 0 0 1 4.538-3.94c3.843-2.883 9.008-5.668 14.556-5.668 5.549 0 10.713 2.785 14.556 5.667a42.942 42.942 0 0 1 4.538 3.94c.447.448.81.832 1.082 1.127-.271.295-.635.68-1.082 1.127a42.942 42.942 0 0 1-4.538 3.94c-3.843 2.883-9.007 5.667-14.556 5.667-5.548 0-10.713-2.785-14.556-5.667a42.932 42.932 0 0 1-4.538-3.94Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.586 32.366V24h2.39v8.366h-2.39Z" fill="#4F4F4F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.586 24h8.366v2.39h-8.366V24ZM23.586 57.463h8.366v2.39h-8.366v-2.39ZM51.074 24h8.366v2.39h-8.366V24ZM51.074 57.463h8.366v2.39h-8.366v-2.39Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.05 32.366V24h2.39v8.366h-2.39ZM23.586 59.854v-8.366h2.39v8.366h-2.39ZM57.05 59.854v-8.366h2.39v8.366h-2.39Z"
        fill="currentColor"
      />
    </svg>
  );
}

export { EyeIconOutlined };
