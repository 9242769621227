import { IPlanObject, ProductInterval } from "@/api";
import { PlanBlock } from "@/components/PlanComparison/PlanComparison";
import { EXTERNAL, PUBLISH_LIVE_PLAN_SLUGS, SELECT_PLAN_SLUGS } from "@/config";
import { findDefaultFreePlanInPlans, findDefaultProPlanInPlans } from "@/helpers";
import { createUrl } from "@/utils";
import type { SelectPlan } from "./types";

type SelectPlanWithStripeData = SelectPlan & {
  monthlyPrice: number;
  yearlyPrice: number;
  stripePlanId: string;
  getCtaUrl: (interval: ProductInterval) => string;
  getDiscount: (interval: ProductInterval) => number;
};

type PlanBlockWithStripeData = PlanBlock & {
  planData: SelectPlanWithStripeData;
};

/**
 * Currently this needs to support both ComparisonPlan and SelectPlanComparisonItem (& BlackFridayPlanComparision), but we aim to remove support for SelectPlanComparisonItem.
 */
function mergeCMSPlanWithStripePlan(cmsPlans: SelectPlan[], stripePlans: IPlanObject[]): SelectPlanWithStripeData[];
function mergeCMSPlanWithStripePlan(cmsPlans: PlanBlock[], stripePlans: IPlanObject[]): PlanBlockWithStripeData[];
function mergeCMSPlanWithStripePlan<T extends SelectPlan | PlanBlock>(cmsPlans: T[], stripePlans: IPlanObject[]) {
  return cmsPlans
    .map((plan) => {
      const planData: SelectPlan = "planData" in plan ? plan.planData : plan;

      let stripePlan = stripePlans.find((stripePlan) => stripePlan.attributes.slug === planData.stripePlanSlug);

      if (!stripePlan && SELECT_PLAN_SLUGS.allFree.includes(planData.stripePlanSlug)) {
        stripePlan = findDefaultFreePlanInPlans(stripePlans);
      }

      if (!stripePlan && SELECT_PLAN_SLUGS.allPro.includes(planData.stripePlanSlug)) {
        stripePlan = findDefaultProPlanInPlans(stripePlans);
      }

      // Should never happen, but just in case there's a CMS data entry error
      if (!stripePlan) return null;

      const mergedPlanData: SelectPlanWithStripeData = {
        ...planData,
        monthlyPrice: stripePlan.attributes["monthly-price"] || 0,
        yearlyPrice: (stripePlan.attributes["yearly-price"] || 0) / 12,
        stripePlanId: stripePlan.id,
        // These properties are deprecated.
        getCtaUrl: makeGetPlanActionUrl({ stripePlan, cmsPlan: planData }),
        getDiscount: (interval: ProductInterval) => {
          const couponIsApplicableToInterval =
            interval === "month" ? planData.stripeCouponAppliesMonthly : planData.stripeCouponAppliesYearly;
          return (couponIsApplicableToInterval && planData.stripeCouponDiscount) || 0;
        },
      };

      return "planData" in plan ? ({ ...plan, planData: mergedPlanData } as PlanBlockWithStripeData) : mergedPlanData;
    })
    .flatMap((v) => (v ? [v] : []));
}

/**
 * @deprecated
 */
const makeGetPlanActionUrl =
  ({
    stripePlan,
    cmsPlan: { stripeCouponCode, stripeCouponAppliesMonthly, stripeCouponAppliesYearly },
  }: {
    stripePlan: IPlanObject;
    cmsPlan: SelectPlan;
  }) =>
  (interval: ProductInterval) => {
    const couponIsApplicableToInterval = interval === "month" ? stripeCouponAppliesMonthly : stripeCouponAppliesYearly;
    const couponToApply = (couponIsApplicableToInterval && stripeCouponCode) || "";

    if (SELECT_PLAN_SLUGS.allPro.includes(stripePlan.attributes.slug)) {
      return createUrl(EXTERNAL.SIGN_UP.HREF, {
        interval,
        product: "select",
        backPath: "/checkout",
        planID: stripePlan.id,
        // fp_ref is the query param that the account portal accepts for coupon codes
        fp_ref: couponToApply,
      });
    } else if (stripePlan.attributes.slug === PUBLISH_LIVE_PLAN_SLUGS.pro) {
      return createUrl(EXTERNAL.SIGN_UP.HREF, {
        interval,
        product: "publish",
        backPath: "/checkout",
        planID: stripePlan.id,
        // fp_ref is the query param that the account portal accepts for coupon codes
        fp_ref: couponToApply,
      });
    }

    return createUrl(EXTERNAL.SELECT.GET_STARTED.HREF);
  };

export type { SelectPlanWithStripeData, PlanBlockWithStripeData };
export { mergeCMSPlanWithStripePlan };
