import classNames from "classnames";
import Link from "next/link";
import { MODULE_KEYS } from "@/modules";
import { Button } from "@narrative-software/narrative-web-ui";
import PhotoCollageLayout from "./PhotoCollageLayout";

export interface DealOfLifeTimeBannerType {
  _modelApiKey: typeof MODULE_KEYS.lifetimeDealModule;
  isSection?: boolean;
  id: string;
  header: string;
  headerClassName?: string;
  details?: string;
  showButton: boolean;
  buttons?: React.ReactNode;
  infoButtonUrl?: string;
}

// Fragment
export const dealOfLifetimeFragment = `
  fragment lifetimeDealFragment on ModuleLifetimeDealRecord {
    _modelApiKey
    id
    header
    details
    infoButtonUrl
  }
`;

const DealOfLifeTimeBanner: React.FC<DealOfLifeTimeBannerType> = ({
  header,
  headerClassName,
  details,
  children,
  showButton = true,
  buttons,
  infoButtonUrl,
}) => {
  return (
    <div className="relative text-white bg-stealth-bomber mb-28 overflow-hidden">
      <PhotoCollageLayout>
        <div className="px-5 md:px-0 pt-9 flex flex-col items-center">
          <h1 className={classNames("text-58 leading-[52px] font-light", headerClassName)}>{header}</h1>
          <div className="text-18 my-3 md:my-10 max-w-[590px]">{details || children}</div>
          {showButton &&
            (buttons ?? (
              <div className="max-w-sm mx-auto">
                <Link href={infoButtonUrl ?? ""}>
                  <Button
                    appearance="primary"
                    className="!border-none lifetime-info-button-gradient normal-case text-12 font-medium px-4 md:px-36 py-5 mt-6"
                  >
                    Learn more
                  </Button>
                </Link>
              </div>
            ))}
        </div>
      </PhotoCollageLayout>
    </div>
  );
};

export default DealOfLifeTimeBanner;
