import classNames from "classnames";
import { DownChevronIcon, InfoTooltip } from "@/components";
import { CheckIcon } from "@/components";
import { BlackFridayProduct } from "@/components/BlackFriday/types";
import { PUBLISH_LIVE_PLAN_SLUGS } from "@/config";
import { useToggleOverflow } from "@/modules/select-plan-comparison/hooks";
import { BLACK_FRIDAY_2024_SLUGS } from "@/utils";
import { BlackFridayPlanButton } from "./BlackFridayPlanButton";

type BlackFridayPlanBoxProps = {
  product: BlackFridayProduct;
  productSlug: string;
  ctaUrl: string;
};

const THEME_FROM_SLUG: Record<string, BlackFridayBoxTheme> = {
  // Select plan
  "select-pro": "select",
  // Publish plan
  "publish-pro": "publish",
  // Black Friday standard edit pack
  edit_bf_1500: "edit",
  // Black Friday ambassador edit pack
  edit_bf_amb_1500: "edit",
};

export type BlackFridayBoxTheme = "select" | "edit" | "publish";

const BlackFridayPlanBox = ({ product, productSlug, ctaUrl }: BlackFridayPlanBoxProps) => {
  const { ref: overflowRef, ...featureOverflow } = useToggleOverflow();

  const { productName, percentOff, originalPrice, isSmall, features, buttonText, stripeProductSlug } = product;

  const savings = (originalPrice / 100) * percentOff;
  const newPrice = originalPrice - savings;

  const theme = THEME_FROM_SLUG[stripeProductSlug];

  const getSavingsText = () => {
    // Edit packs
    if (BLACK_FRIDAY_2024_SLUGS.includes(stripeProductSlug)) {
      return (
        <div className="mt-1 leading-relaxed">
          <span className="text-shadyLady">
            Usually <span className="line-through">${originalPrice}</span> USD.
          </span>{" "}
          Now only ${newPrice}!
        </div>
      );
    }
    // Publish plan
    if (stripeProductSlug === PUBLISH_LIVE_PLAN_SLUGS.pro) {
      return (
        <div className="mt-1 leading-relaxed">
          ${newPrice / 12} USD p/m for Publish Pro, billed yearly.
          <span className="text-shadyLady">
            {" "}
            Usually <span className="line-through">${originalPrice / 12}</span> p/m.
          </span>
        </div>
      );
    }

    // Select plan
    return (
      <div className="mt-1 leading-relaxed">
        Worth ${originalPrice} USD. Now only ${newPrice}!
      </div>
    );
  };

  return (
    <div
      id={productSlug}
      className={classNames("w-full flex flex-col lg:flex-1 relative bg-gradient-to-tr p-[2px] rounded-[2px]", {
        "md:row-span-2": !isSmall,
        "from-[#31256F] to-[#8B4597] via-[#4f429c] p-[2px]": theme === "select",
        "from-[#B44893] to-[#B1017C] via-[#B44893] p-[2px]": theme === "edit",
        "bg-slowpoke": theme === "publish",
      })}
    >
      {/* Yellow pill in top right corner */}
      <div className="absolute right-[2px] top-[2px] bg-[#FFDA18] text-stealth-bomber py-2 px-4 rounded-bl-lg font-semibold font-18">
        Save ${savings}
      </div>

      <div className="pb-9 md:pb-10 flex flex-col h-full pt-9 bg-satans-heart rounded-[2px]">
        {/* Header */}
        <div
          className={classNames("flex flex-col items-center px-5 pt-6 pb-6 text-center  border-gumboot", {
            "border-b-1": !isSmall,
          })}
        >
          <h3 className={classNames("relative mb-2.5 text-h3 leading-none md:text-34 font-light")}>{productName}</h3>
          <div className="relative text-6xl font-semibold leading-none">{percentOff}% off</div>
          <div className="text-14 mt-1 leading-relaxed">{getSavingsText()}</div>
        </div>
        {/* Features */}
        {!isSmall && (features ?? []).length > 0 && (
          <div className="px-5 md:px-10 pt-8 relative">
            <div
              ref={overflowRef}
              className={classNames("transition-[max-height] overflow-hidden", {
                "max-h-[324px]": !featureOverflow.isOpen,
                "max-h-[600px]": featureOverflow.isOpen,
              })}
            >
              <ul>
                {features!.map((feature) => (
                  <li
                    key={feature.name}
                    className={classNames("text-sm flex items-center justify-between mb-2", {
                      "text-shady-lady": !feature.isHighlighted,
                    })}
                  >
                    <div className="flex items-center md:mr-5">
                      <div className="mr-5 text-gengar">
                        <CheckIcon />
                      </div>
                      {feature.name}
                    </div>
                    {feature.description && <InfoTooltip description={feature.description} special="bf-select" />}
                  </li>
                ))}
              </ul>
            </div>
            {featureOverflow.hasOverflow && (
              <div className="relative">
                <button
                  className="text-sm pt-0 mb-3 flex items-center text-robot-friend relative z-10"
                  onClick={() => featureOverflow.toggle()}
                >
                  {featureOverflow.isOpen ? "Less" : "More"}
                  <DownChevronIcon
                    className={classNames("origin-center ml-2", { "rotate-180": featureOverflow.isOpen })}
                  />
                </button>
                {!featureOverflow.isOpen && (
                  <div className="absolute bg-gradient-to-t from-satans-heart to-transparent w-full -top-[60px] h-[60px]"></div>
                )}
              </div>
            )}
          </div>
        )}
        {/* CTA */}
        <div className="px-5 md:px-10 mt-auto">
          <BlackFridayPlanButton theme={theme} href={ctaUrl}>
            {buttonText}
          </BlackFridayPlanButton>
        </div>
      </div>
    </div>
  );
};

export { BlackFridayPlanBox };
