import React from "react";

function LightningBoltIconOutlined() {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none">
      <circle cx="42" cy="42" r="40" stroke="currentColor" strokeWidth="3" />
      <mask id="path-2-inside-1_11_201" fill="white">
        <path d="M38.8236 45.5257H31.4736L48.2736 18.2257L45.1236 37.1257H54.5736L35.6736 62.3257L38.8236 45.5257Z" />
      </mask>
      <path
        d="M31.4736 45.5257L29.7703 44.4775L27.8945 47.5257H31.4736V45.5257ZM38.8236 45.5257L40.7894 45.8943L41.2335 43.5257H38.8236V45.5257ZM35.6736 62.3257L33.7079 61.9572L37.2736 63.5257L35.6736 62.3257ZM54.5736 37.1257L56.1736 38.3257L58.5736 35.1257H54.5736V37.1257ZM45.1236 37.1257L43.1509 36.7969L42.7627 39.1257H45.1236V37.1257ZM48.2736 18.2257L50.2464 18.5545L46.5703 17.1775L48.2736 18.2257ZM31.4736 47.5257H38.8236V43.5257H31.4736V47.5257ZM36.8579 45.1571L33.7079 61.9572L37.6394 62.6943L40.7894 45.8943L36.8579 45.1571ZM37.2736 63.5257L56.1736 38.3257L52.9736 35.9257L34.0736 61.1257L37.2736 63.5257ZM54.5736 35.1257H45.1236V39.1257H54.5736V35.1257ZM47.0964 37.4545L50.2464 18.5545L46.3009 17.8969L43.1509 36.7969L47.0964 37.4545ZM46.5703 17.1775L29.7703 44.4775L33.1769 46.5739L49.977 19.2739L46.5703 17.1775Z"
        fill="currentColor"
        mask="url(#path-2-inside-1_11_201)"
      />
      <path
        d="M54.75 29.25C61.5155 36.0155 61.5155 46.9845 54.75 53.75M30.25 53.75C23.4845 46.9845 23.4845 36.0155 30.25 29.25"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export { LightningBoltIconOutlined };
