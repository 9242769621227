// these two variables will indicate where in the code the black friday sales need to be removed
// BLACKFRIDAY2024TEMP
const EDIT_BLACK_FRIDAY_2024_SALE_SLUG = "edit_bf_1500";
const EDIT_BLACK_FRIDAY_2024_AMBASSADOR_SALE_SLUG = "edit_bf_amb_1500";

const BLACK_FRIDAY_2024_SLUGS = [
  EDIT_BLACK_FRIDAY_2024_SALE_SLUG,
  EDIT_BLACK_FRIDAY_2024_AMBASSADOR_SALE_SLUG,
] as const;

type PackSlug = "edit_payg" | "edit_20k_sale" | `${number}_edit` | (typeof BLACK_FRIDAY_2024_SLUGS)[number];

type CreditPurchasingPack = {
  id: string;
  credits: number;
  currency: string;
  description: string;
  insertedAt: string;
  name: string;
  productName: string;
  slug: PackSlug;
  status: string;
  perCreditAmount: number;
  unitAmount: number;
  updatedAt: string;
  saving: number;
  isSale: boolean;
};

type ServicesCreditPurchasingPacksResponse = {
  data: ServicesCreditPurchasingPack[];
};

type ServicesCreditPurchasingPack = {
  attributes: {
    credits: number;
    currency: string;
    description: string;
    id: string;
    "inserted-at": string;
    name: string;
    "product-name": string;
    slug: string;
    status: string;
    "unit-amount": number;
    "updated-at": string;
    "is-sale": boolean;
  };
};

function deserializeCreditPurchasingPacks(packs: string) {
  const packsJson: ServicesCreditPurchasingPacksResponse = JSON.parse(packs as string);
  const deserializedPacks = packsJson.data.map(deserializeCreditPurchasingPack);
  const maxPerCreditAmount = deserializedPacks.reduce((min, pack) => Math.max(min, pack.perCreditAmount), -Infinity);
  return deserializedPacks
    .map((pack) => ({
      ...pack,
      saving: Math.floor((maxPerCreditAmount - pack.perCreditAmount) * pack.credits),
    }))
    .sort((a, b) => a.unitAmount - b.unitAmount);
}

function deserializeCreditPurchasingPack({
  attributes: {
    credits,
    currency,
    description,
    id,
    "inserted-at": insertedAt,
    name,
    "product-name": productName,
    slug,
    status,
    "unit-amount": unitAmount,
    "updated-at": updatedAt,
    "is-sale": isSale,
  },
}: ServicesCreditPurchasingPack): CreditPurchasingPack {
  return {
    credits,
    currency,
    description,
    id,
    insertedAt,
    name,
    productName,
    slug: slug as PackSlug,
    status,
    perCreditAmount: unitAmount / credits,
    unitAmount,
    updatedAt,
    saving: 0,
    isSale,
  };
}

export type { ServicesCreditPurchasingPacksResponse, ServicesCreditPurchasingPack, CreditPurchasingPack };
export { deserializeCreditPurchasingPacks };
export { EDIT_BLACK_FRIDAY_2024_SALE_SLUG, EDIT_BLACK_FRIDAY_2024_AMBASSADOR_SALE_SLUG, BLACK_FRIDAY_2024_SLUGS };
