import React from "react";
import classNames from "classnames";
import { BlackFridayBoxTheme } from "./BlackFridayPlanBox";

interface BlackFridayPlanButtonProps {
  children: React.ReactNode;
  theme: BlackFridayBoxTheme;
  href: string;
}

function BlackFridayPlanButton({ children, theme, href }: BlackFridayPlanButtonProps) {
  return (
    <div className="bg-white rounded-[3px]">
      <a
        href={href}
        target="_blank"
        className={classNames("h-[55px] flex items-center justify-center text-white hover:opacity-90 rounded-[2px]", {
          "bg-gradient-to-r from-starmie to-ekans via-gengar": theme === "select",
          "bg-gradient-to-r from-ekans from-60% via-[#B44893] from-126% to-[#B1017C]": theme === "edit",
          "bg-gradient-to-r from-[#da4250] to-[#CC5385] via-[#f16370]": theme === "publish",
        })}
      >
        {children}
      </a>
    </div>
  );
}

export { BlackFridayPlanButton };
