import { Api, IPlanObject, ProductInterval } from "@/api";
import { BlackFridayProduct, BlackFridayProductWithStripeData } from "@/components/BlackFriday/types";
import { EXTERNAL } from "@/config";
import { getPublishProPlan, getSelectProPlan } from "@/helpers";
import {
  BLACK_FRIDAY_2024_SLUGS,
  CreditPurchasingPack,
  EDIT_BLACK_FRIDAY_2024_AMBASSADOR_SALE_SLUG,
} from "./edit-packs";
import { createUrl } from "./general";

/**
 * For every product fetched from Dato, we try to find the corresponding stripe product
 * and add the stripe product name and id to the product object.
 * If we can't find the stripe product, we return undefined and filter it out.
 */
const getBlackFridayProductsWithStripeData = (
  products: BlackFridayProduct[],
  editPack?: CreditPurchasingPack,
  plans?: IPlanObject[],
): BlackFridayProductWithStripeData[] => {
  return products
    .map((product) => {
      if (BLACK_FRIDAY_2024_SLUGS.includes(product.stripeProductSlug) && editPack?.slug === product.stripeProductSlug) {
        /**
         * This is the edit credits pack, we have to treat it a bit different.
         */
        return {
          ...product,
          stripeProductName: editPack.productName,
          stripeProductId: editPack.id,
        };
      } else {
        const stripeProduct = plans?.find((stripePlan) => stripePlan.attributes.slug === product.stripeProductSlug);
        if (stripeProduct) {
          return {
            ...product,
            stripeProductName: stripeProduct.attributes.name,
            stripeProductId: stripeProduct.id,
          };
        }
      }
    })
    .filter((product): product is BlackFridayProductWithStripeData => product !== undefined);
};

/**
 * This is needed for the Black Friday banner on the select pages, where we just need the select pro product
 * <BlackFridaySelectPricingBanner />
 */
const fetchBlackFridaySelectProductWithStripeData = async (
  plans: IPlanObject[],
): Promise<BlackFridayProductWithStripeData | null> => {
  try {
    const blackFridayPlan = getSelectProPlan(plans);

    if (!blackFridayPlan) {
      return null;
    }

    const graphqlRequest = {
      query: `
        {
         blackFriday2024 {
              products {
                buttonText
                features {
                    description
                    isHighlighted
                    name
                }
                isSmall
                originalPrice
                percentOff
                productName
                stripeProductSlug
                stripeCouponCode
              }
          }
        }
      `,
    };

    const {
      data: { data: initialData },
    } = await Api.datocmsGraphqlRequest<{
      blackFriday2024: {
        products: BlackFridayProduct[];
      };
    }>(graphqlRequest);

    const [constructedProduct] = getBlackFridayProductsWithStripeData(initialData.blackFriday2024.products, undefined, [
      blackFridayPlan,
    ]);

    return constructedProduct;
  } catch (e) {
    return null;
  }
};

/**
 * This is used on the publish pricing page, where we just need the publish pro product
 */
const getBlackFridayPublishProductWithStripeData = async (
  plans: IPlanObject[],
): Promise<BlackFridayProductWithStripeData | null> => {
  try {
    const blackFridayPlan = getPublishProPlan(plans);

    if (!blackFridayPlan) {
      return null;
    }

    const graphqlRequest = {
      query: `
          {
           blackFriday2024 {
                products {
                  buttonText
                  features {
                      description
                      isHighlighted
                      name
                  }
                  isSmall
                  originalPrice
                  percentOff
                  productName
                  stripeProductSlug
                  stripeCouponCode
                }
            }
          }
        `,
    };

    const {
      data: { data: initialData },
    } = await Api.datocmsGraphqlRequest<{
      blackFriday2024: {
        products: BlackFridayProduct[];
      };
    }>(graphqlRequest);

    const [constructedProduct] = getBlackFridayProductsWithStripeData(initialData.blackFriday2024.products, undefined, [
      blackFridayPlan,
    ]);

    return constructedProduct;
  } catch (e) {
    return null;
  }
};

/**
 * Creates the url to purchase the product.
 * In case of the select and publish plans, it directly redirects to the checkout page.
 * In case of the edit pack, it redirects to the subscriptions page in the account portal
 * and passes the pack id as a query param.
 */
const createBlackFridayProductUrl = ({
  productSlug,
  stripeProductId,
  couponCode,
}: {
  productSlug: string;
  stripeProductId: string;
  couponCode?: string;
}): string => {
  let params: {
    backPath?: string;
    packId?: string;
    interval?: ProductInterval;
    planID?: string;
    fp_ref?: string;
    is_amb?: string;
  } = {};

  if (BLACK_FRIDAY_2024_SLUGS.includes(productSlug)) {
    // Params for edit packs
    params = {
      backPath: "/subscriptions",
      packId: stripeProductId,
    };

    // In case of the secret ambassador pack, we pass an additional query param
    // so the accounts portal can act on it easier.
    if (productSlug === EDIT_BLACK_FRIDAY_2024_AMBASSADOR_SALE_SLUG) {
      params["is_amb"] = "true";
    }
  } else {
    // Params for select and publish plans
    params = {
      interval: ProductInterval.Year,
      backPath: "/checkout",
      planID: stripeProductId,
    };

    if (couponCode) {
      params["fp_ref"] = couponCode;
    }
  }
  return createUrl(EXTERNAL.SIGN_IN.HREF, params);
};

export {
  getBlackFridayProductsWithStripeData,
  fetchBlackFridaySelectProductWithStripeData,
  getBlackFridayPublishProductWithStripeData,
  createBlackFridayProductUrl,
};
