import axios, { AxiosRequestConfig } from "axios";
import { IAwardsResponse, IGetPlansResponse } from "@/api";
import { API_URL, DATOCMS_API_TOKEN, SELECT_FREE_PROJECT_THRESHOLD_ENDPOINT, acFormProxy, acFormUrl } from "@/config";
import { ApiHelper } from "@/helpers";
import { GraphqlRequest, IEntryForm } from "@/types";
import { CreditPurchasingPack, createUrl, deserializeCreditPurchasingPacks } from "@/utils";

export const request = {
  GET: <T>(url: string, params = {}, headers = {}, config: AxiosRequestConfig = {}) => {
    return axios.get<T>(url, { params, headers, ...config });
  },

  POST: <T>(url: string, body = {}, headers = {}, config = {}) => {
    return axios.post<T>(url, body, { headers, ...config });
  },
};

export const Api = {
  /**
   * Get plans
   * Retrieves a list of 'live' plans.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPlans: (data: Record<string, any> = {}) => {
    const params = { ...data, "filter[status]": 1 };
    const headers = {
      Accept: "application/vnd.api.v2+json",
      "Content-Type": "application/vnd.api+json",
    };
    return request.GET<IGetPlansResponse>(`${API_URL}/plans`, params, headers);
  },

  /**
   * Get packs
   * Retrieves a list of pricing packs.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPacks: (data: Record<string, any> = {}) => {
    const params = { ...data };
    const headers = {
      Accept: "application/vnd.api.v2+json",
      "Content-Type": "application/vnd.api+json",
    };
    return request.GET<CreditPurchasingPack[]>(`${API_URL}/packs`, params, headers, {
      transformResponse: deserializeCreditPurchasingPacks,
    });
  },

  getSelectFreeProjectThreshold: () => request.GET<number>(SELECT_FREE_PROJECT_THRESHOLD_ENDPOINT),

  /**
   * Get data from Dato CMS
   */
  datocmsGraphqlRequest: <T>({ query, variables, preview }: GraphqlRequest) => {
    const url = `https://graphql.datocms.com/${preview ? "preview" : ""}`;
    const body = { query, variables };
    const headers = { Authorization: `Bearer ${DATOCMS_API_TOKEN}` };
    return request.POST<{ data: T } | { errors: { message: string }[]; data: T }>(url, body, headers);
  },

  /**
   * Send Active Campaign form
   *
   * These are forms created within Active Campaign which we trigger with GET requests
   * + query strings which target the form and pass the field values.
   *
   * In Active Campaign when viewing a form click "Integrate" in the top right and you can
   * find the hidden inputs which need to be passed for each form in the HTML it offers you.
   *
   * This is essentially bypassing the use of the AC API and leveraging their form builder instead.
   */
  sendActiveCampaignsForm: (data: Record<string, string>) => {
    const url = createUrl(acFormUrl, { ...data, jsonp: "true" });
    const body = { url };
    const headers = { "Content-Type": "application/json" };
    return request.POST(acFormProxy, body, headers);
  },

  /**
   * Send Active Campaign’s form
   */
  sendAwardsEntry: (data: IEntryForm, signal: AbortSignal) => {
    const body = ApiHelper.getAwardsEntryBody(data);
    const headers = {
      Accept: "application/vnd.api.v2+json",
      "Content-Type": "application/vnd.api+json",
    };
    return request.POST<IAwardsResponse>(`${API_URL}/award-submission`, body, headers, { signal });
  },
};
