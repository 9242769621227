import React from "react";
import Image from "next/image";
import { SELECT_PLAN_SLUGS } from "@/config";
import { SelectPlanWithStripeData } from "@/modules/select-plan-comparison/utils";
import { createBlackFridayProductUrl } from "@/utils/black-friday";
import { Tooltip } from "@narrative-software/narrative-web-ui";
import { EyeIconOutlined } from "../icons/EyeIconOutlined";
import { LightningBoltIconOutlined } from "../icons/LightningBoltIconOutlined";
import { BlackFridayPlanBox } from "./BlackFridayPlanBox";
import { BlackFridayProductWithStripeData } from "./types";

interface Props {
  products: BlackFridayProductWithStripeData[];
  planHeader: string;
}

/**
 * A fragment from old black friday pages.
 * This function is called from the select plan boxes on the select pricing sections.
 *
 * We should investigate if this code is still relevant and attempt to remove it, if we can.
 */
export const getPlanSpecialType = (plan: SelectPlanWithStripeData) => {
  if (!plan.blackFridayPlan) return;

  if (plan.stripePlanSlug === SELECT_PLAN_SLUGS.defaultPro) {
    return "bf-select";
  }

  return "bf-publish";
};

const BlackFridayPlanComparison = React.forwardRef<HTMLDivElement, Props>(({ products, planHeader }, ref) => {
  return (
    <div className="flex flex-col items-center px-5" ref={ref}>
      <h2 className="font-light text-34 md:text-58 text-center mb-12">{planHeader}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full max-w-[988px] relative">
        {products.map((product) => (
          <BlackFridayPlanBox
            key={product.stripeProductSlug}
            product={product}
            productSlug={product.stripeProductSlug}
            ctaUrl={createBlackFridayProductUrl({
              productSlug: product.stripeProductSlug,
              stripeProductId: product.stripeProductId,
              couponCode: product.stripeCouponCode,
            })}
          />
        ))}

        {/* Images - Hidden from sm and under */}
        <div className="absolute hidden md:flex bottom-44 -left-[300px] 2xl:-left-[400px] w-[245px] h-[163px] 2xl:w-[306px] 2xl:h-[204px] z-0">
          <Image src="/images/black-friday/bf-deal-7.jpg" layout="fill" alt="Black Friday Deal 1" />
        </div>
        <div className="absolute hidden md:flex bottom-0 -right-[300px] 2xl:-right-[400px] w-[213px] h-[150px] 2xl:w-[266px] 2xl:h-[187px] z-0">
          <Image src="/images/black-friday/bf-deal-8.jpg" layout="fill" alt="Black Friday Deal 1" />
        </div>
        <div className="hidden md:flex absolute top-[120px] -right-[180px] 2xl:-right-[240px] z-0 text-gumboot">
          <EyeIconOutlined />
        </div>
        <div className="hidden md:flex absolute -top-[180px] -left-[200px] 2xl:-left-[220px] z-0 text-gumboot">
          <LightningBoltIconOutlined />
        </div>
      </div>
      <Tooltip />
    </div>
  );
});

BlackFridayPlanComparison.displayName = "BlackFridayPlanComparison";

export default BlackFridayPlanComparison;
