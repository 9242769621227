import { StructuredTextGraphQlResponse } from "react-datocms";
import { IPlanObject, Api } from "@/api";
import { getPlanSpecialType } from "@/components/BlackFriday/BlackFridayPlanComparison";
import { useBillingIntervalToggle } from "@/components/pricing/BillingIntervalToggle/useBillingIntervalToggle";
import { SELECT_PLAN_SLUGS } from "@/config";
import { getLiveSelectPlans } from "@/helpers";
import { ImageType } from "@/types";
import { PlanComparisonSection } from "../../components/PlanComparison/PlanComparisonSection";
import { MODULE_KEYS } from "../config";
import { SelectPlanComparisonItem } from "./SelectPlanComparisonItem";
import { SelectPlanComparisonLayout } from "./SelectPlanComparisonLayout";
import { SelectPlanComparisonMobileNav } from "./SelectPlanComparisonMobileNav";
import { SelectPlan } from "./types";
import { mergeCMSPlanWithStripePlan } from "./utils";

/**
 * Add this to getStaticProps of the relevant page to get the plans data from stripe for use with this module.
 */
async function getPlansStaticProp() {
  const response = await Api.getPlans({ "filter[product-name]": "Select" });
  return getLiveSelectPlans(response.data.data);
}

const selectPlanComparisonFragment = `
  fragment selectPlanComparisonFragment on ModuleSelectPlanComparisonRecord {
    __typename
    _modelApiKey
    id
    title
    description {
      value
    }
    isUpgradeMode
    isDowngradeMode
    sectionBackgroundImage {
      responsiveImage(imgixParams: { auto: format }) {
        ...responsiveImageFragment
      }
    }
    showBillingIntervalControl
    selectPlans {
      id
      planName
      description {
        value
      }
      stripePlanSlug
      ctaLabel
      ctaLabelDowngrade
      ctaLabelUpgrade
      features {
        id
        name {
          value
        }
        description
      }
      blackFridayPlan
      stripeCouponCode
      stripeCouponDiscount
      stripeCouponAppliesYearly
      stripeCouponAppliesMonthly
    }
    prioritisePro
    isDarkMode
  }
`;

type SelectPlanComparisonFragmentType = {
  _modelApiKey: typeof MODULE_KEYS.selectPlanComparison;
  isSection?: boolean;
  id: string;
  title: string;
  description: StructuredTextGraphQlResponse;
  isUpgradeMode: boolean;
  isDowngradeMode: boolean;
  sectionBackgroundImage?: ImageType;
  showBillingIntervalControl: boolean;
  selectPlans: SelectPlan[];
  prioritisePro?: boolean;
  isDarkMode?: boolean;
};

type SelectPlanComparisonProps = SelectPlanComparisonFragmentType & {
  stripePlans: IPlanObject[];
};

/**
 * @deprecated - use PlanComparison block + component instead. It separates plan data from presentation concerns, and is more flexible.
 */
const SelectPlanComparison = ({
  title,
  description,
  isUpgradeMode,
  isDowngradeMode,
  showBillingIntervalControl,
  sectionBackgroundImage,
  selectPlans,
  stripePlans,
  prioritisePro,
  isDarkMode,
}: SelectPlanComparisonProps) => {
  const [billingInterval, setBillingInterval] = useBillingIntervalToggle();
  const plansWithPriceData = mergeCMSPlanWithStripePlan(selectPlans, stripePlans);

  const isBlackFridayPlanPresent = plansWithPriceData.some((plan) => plan.blackFridayPlan);
  // Switch stand pro plan for Black Friday plan if present and on yearly view
  const plansFilteredForBlackFriday = isBlackFridayPlanPresent
    ? plansWithPriceData.filter((plan) =>
        plan.stripePlanSlug === SELECT_PLAN_SLUGS.defaultFree || billingInterval === "month"
          ? !plan.blackFridayPlan
          : plan.blackFridayPlan,
      )
    : plansWithPriceData;

  return (
    <PlanComparisonSection
      title={title}
      description={description}
      showBillingIntervalControl={showBillingIntervalControl}
      darkMode={isDarkMode}
      backgroundImage={sectionBackgroundImage}
      billingInterval={billingInterval}
      setBillingInterval={setBillingInterval}
      planComparison={
        <SelectPlanComparisonLayout>
          {plansFilteredForBlackFriday.map((plan, i) => (
            <SelectPlanComparisonItem
              {...plan}
              key={plan.id}
              position={i}
              billingInterval={billingInterval}
              mode={
                isDowngradeMode ? "downgrade" : isUpgradeMode ? "upgrade" : prioritisePro ? "prioritisePro" : "normal"
              }
              special={getPlanSpecialType(plan)}
            />
          ))}
        </SelectPlanComparisonLayout>
      }
      planComparisonMobileNav={
        <SelectPlanComparisonMobileNav
          plans={plansFilteredForBlackFriday.map(({ planName: name, stripePlanSlug: slug }) => ({ name, slug }))}
        />
      }
    />
  );
};

export type { SelectPlanComparisonFragmentType };

export { selectPlanComparisonFragment, SelectPlanComparison, getPlansStaticProp };
